/* http://meyerweb.com/eric/tools/css/reset/ | v2.0 | 20110126 | License: none (public domain) */
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after,q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}

// additional resets
* { box-sizing: border-box; }
img { display: block; }

@font-face {
	font-family: "Nexa";
	src: url("fonts/Nexa-Regular.woff2") format("woff2");
	font-weight: 400;
	font-style: normal;
}
@font-face {
	font-family: "Nexa";
	src: url("fonts/Nexa-Bold.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
}
@font-face {
	font-family: "Nexa Book";
	src: url("fonts/Nexa-Book.woff2") format("woff2");
	font-weight: 700;
	font-style: normal;
}

// variables
$nexa: "Nexa", sans-serif;
$book: "Nexa Book", sans-serif;
$black: #000000;
$white: #F8F8F8;
$red: #F3523F;
$gray1: #D9D9D9;
$gray2: #B3B3B3;
$gray3: #808080;
$gray4: #4C4C4C;
$gray5: #1A1A1A;

// mixins
@mixin bp($size) {
	$sm: 480px;
	$md: 768px;
	$lg: 1024px;
	$xl: 1440px;

	@if $size == sm {
		@media (min-width: #{$sm}){ @content; }
	}
	@else if $size == md {
		@media (min-width: #{$md}){ @content; }
	}
	@else if $size == lg {
		@media (min-width: #{$lg}){ @content; }
	}
	@else if $size == xl {
		@media (min-width: #{$xl}){ @content; }
	}
}

// everything is scoped to #c5-container
body {
	background-color: $black;
}
header {
	width: 100%;
	height: 100px;
	position: fixed;
	top: 0;
	z-index: 99;
	background-color: $black;
	.inner {
		width: 100%;
		margin: auto;
		padding: 40px 0;
		display: flex;
		align-items: center;
		font-size: 14px; // recommend 16px for everything
		line-height: 132%;
		color: $white;
		img {
			width: 100px;
			position: relative;
			z-index: 2;
			@include bp(xl) {
				width: auto;
			}
		}
		.flex {
			width: auto;
			margin-left: auto;
			align-items: center;
		}
		svg {
			margin: -1px 0 0 12px;
			@include bp(xl) {
				margin: -2px 0 0 32px;
			}
		}
	}
}
nav {
	.desktop-nav {
		display: none;
		@include bp(lg) {
			display: flex;
		}
	}
	.mobile-nav {
		width: 100vw !important;
		height: 100vh;
		margin-left: -50vw !important;
		margin-right: -50vw;
		position: absolute;
		top: 0;
		left: 50%;
		right: 50%;
		background-color: $black;
		font-family: $nexa;
		font-size: 16px;
		font-weight: 300;
		z-index: 1;
		visibility: hidden;
		opacity: 0;
		@include bp(lg) {
			display: none;
		}
		.inner {
			position: relative;
			top: 120px;
			align-items: flex-start;
			svg {
				margin: 0;
				position: absolute;
				top: -80px;
				right: 0;
			}
			a {
				margin: 0 0 16px;
			}
		}
		.big-links {
			margin: 120px 0 0;
			align-items: flex-start;
			h3:not(:last-of-type) {
				margin-bottom: 24px;
			}
		}
	}
	a {
		position: relative;
		color: $gray3;
		transition: color 0.3s ease 0.2s;
		&:not(:last-of-type) {
			margin-right: 20px;
			@media screen and (min-width: 1200px) {
				margin-right: 32px;
			}
		}
		&:after {
			content: "";
			width: 0%;
			height: 1px;
			margin-top: 5px;
			position: absolute;
			bottom: -5px;
			left: 0;
			background-color: $red;
			transition: width 0.3s ease 0.2s;
		}
		&.active,
		&:hover {
			color: $white;
			&:after {
				width: 100%;
			}
		}
	}
}
section {
	margin-top: 100px;
	position: relative;
	@include bp(md) {
		margin-top: 320px;
	}
}
aside {
	display: flex;
	justify-content: center;
	> .flex {
		max-width: 644px;
	}
}
video {
	width: 100%;
	height: auto;
}
h1 {
	font-family: $nexa;
	// font-size: 44px;
	font-size: clamp(2.75rem, 1.0774647887323945rem + 7.136150234741784vw, 7.5rem);
	font-weight: 700;
	line-height: 116%;
	color: $white;
	@include bp(md) {
		// font-size: 70px;
	}
	@include bp(lg) {
		// font-size: 95px;
		line-height: 108%;
	}
	@include bp(xl) {
		// font-size: 120px;
	}
	span {
		color: $red;
	}
	&.giant {
		// font-size: 54px;
		font-size: clamp(3.375rem, 1.4823943661971832rem + 8.075117370892018vw, 8.75rem);
		@include bp(md) {
			// font-size: 80px;
		}
		@include bp(lg) {
			// font-size: 110px;
			line-height: 112%;
		}
		@include bp(xl) {
			// font-size: 140px;
		}
	}
}
h2 {
	font-family: $nexa;
	// font-size: 48px;
	font-size: clamp(3rem, 1.9436619718309858rem + 4.507042253521127vw, 6rem);
	font-weight: 700;
	line-height: 108%;
	color: $white;
	@include bp(xl) {
		// font-size: 96px;
	}
	span {
		color: $red;
	}
}
h3 {
	font-family: $nexa;
	font-size: 32px;
	font-weight: 700;
	line-height: 132%;
	color: $white;
	@include bp(xl) {
		font-size: 56px;
		line-height: 108%;
	}
}
h4 {
	font-family: $book;
	font-size: 28px;
	font-weight: 300;
	line-height: 132%;
	color: $white;
	@include bp(xl) {
		font-size: 40px;
		line-height: 132%;
	}
}
h5 {
	font-family: $nexa;
	font-size: 24px;
	font-weight: 300;
	line-height: 132%;
	color: $white;
	@include bp(xl) {
		font-size: 32px;
		line-height: 164%;
	}
}
h6 {
	font-family: $nexa;
	font-size: 20px;
	font-weight: 700;
	line-height: 164%;
	color: $white;
	@include bp(xl) {
		font-size: 24px;
	}
}
p {
	font-family: $nexa;
	font-size: 18px;
	font-weight: 300;
	line-height: 120%;
	color: $white;
	@include bp(xl) {
		font-size: 18px;
		line-height: 25.2px;
	}
	&.md {
		font-size: 16px;
		line-height: 164%;
	}
	&.sm {
		font-size: 14px;
		line-height: 132%;
	}
	&.ti {
		font-size: 12px;
		line-height: 164%;
	}
}
a {
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	font-family: $nexa;
	font-size: 16px;
	font-weight: 700;
	line-height: 164%;
	color: $gray3;
	&.btn-primary,
	&.btn-secondary {
		position: relative;
		// display: flex;
		// align-items: center;
		// justify-content: center;
		background-color: $black;
		color: $white;
		cursor: pointer;
	}
	&.btn-primary {
		padding: 8px 24px;
		border: 1px solid $gray2;
		position: relative;
		clip-path: inset(0);
		transform: translateZ(0px);
		&:before,
		&:after {
			content: "";
			width: 22px;
			height: 22px;
			position: absolute;
			background-color: $black;
			transform: rotate(45deg);
			transition: transform 0.4s ease;
			z-index: 1;
		}
		&:before {
			border-right: 1px solid $gray1;
			top: -22px;
			left: -22px;
		}
		&:after {
			border-left: 1px solid $gray1;
			bottom: -22px;
			right: -22px;
		}
		&:hover {
			&:before {
				transform: translate(10px, 10px) rotate(45deg);
			}
			&:after {
				transform: translate(-10px, -10px) rotate(45deg);
			}
		}
	}
	&.btn-secondary {
		&:after {
			content: "";
			width: 0%;
			height: 1px;
			margin-top: 5px;
			position: absolute;
			bottom: 5px;
			left: 0;
			background-color: $red;
			transition: width 0.3s ease 0.2s;
		}
		&.active,
		&:hover {
			color: $white;
			&:after {
				width: 100%;
			}
		}
	}
	span {
		opacity: 0;
	}
}
button {
	padding: 8px 24px;
	border: 1px solid $gray2;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: $black;
	font-family: $nexa;
	font-size: 16px;
	font-weight: 700;
	line-height: 164%;
	color: $white;
	cursor: pointer;
	transform: translateZ(0px);
	&.primary {
		position: relative;
		clip-path: inset(0);
		&:before,
		&:after {
			content: "";
			width: 22px;
			height: 22px;
			position: absolute;
			background-color: $black;
			transform: rotate(45deg);
			transition: transform 0.4s ease;
			z-index: 1;
		}
		&:before {
			border-right: 1px solid $gray1;
			top: -22px;
			left: -22px;
		}
		&:after {
			border-left: 1px solid $gray1;
			bottom: -22px;
			right: -22px;
		}
		&:hover {
			&:before {
				transform: translate(10px, 10px) rotate(45deg);
			}
			&:after {
				transform: translate(-10px, -10px) rotate(45deg);
			}
		}
	}
}

.inner {
	max-width: calc(100% - 64px);
	height: 100%;
	margin: 0 auto;
	position: relative;
	display: flex;
	@include bp(md) {
		max-width: calc(100% - 100px);
	}
	@include bp(lg) {
		max-width: calc(100% - 130px);

	}
	@include bp(xl) {
		max-width: 1310px; //update font and change to 1312px... or 1310????? // was 1340
	}
}
.flex {
	// width: 100%;
	height: 100%;
	display: flex;
	&.v-center {
		align-items: center;
	}
}
.column {
	flex-direction: column;
}
.redline {
	padding-left: 16px;
	border-left: 1px solid $red;
}

.hero {
	// margin-top: 150px;
	margin-top: 0;
	padding-top: 150px;
	@include bp(md) {
		// margin-top: 214px; // 111px w/ smoothscroll disabled
		padding-top: 214px;
	}
	.inner > .flex {
		@include bp(lg) {
			flex-direction: row;
		}
	}
	.flex-left {
		max-width: 862px;
	}
	.flex-right {
		min-width: 250px;
		margin-top: 24px;
		flex-direction: column;
		@include bp(lg) {
			margin: auto 0 22px 32px;
		}
	}
	p {
		max-width: 417px;
		margin-bottom: 32px;
	}
	.social {
		align-items: center;
		a:not(:last-of-type) {
			margin-right: 8px;
			margin-left: auto;
			@include bp(md) {
				margin-left: 0;
			}
		}
	}
	button {
		margin-right: 32px;
		align-self: flex-start;
	}
	.hero-video {
		width: 100%;
		height: auto;
		margin-top: 73px;
		position: relative;
		background: black;
		&:hover .fold {
			border-width: 0;
		}
	}
	.video-folds {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
	}
	.fold {
		width: 0px;
		height: 0px;
		position: absolute;
		border-style: solid;
		background: transparent;
		display: block;
		border-color: transparent #000 transparent #000;
		transition: border-width 0.4s ease;
		&.top-right {
			top: -1px;
			right: -1px;
			border-width: 0px 50px 50px 0px;
			@include bp(lg) {
				border-width: 0px 100px 100px 0px;
			}
		}
		&.bot-left {
			bottom: -1px;
			left: -1px;
			border-width: 50px 0px 0px 50px;
			@include bp(lg) {
				border-width: 100px 0px 0px 100px;
			}
		}
	}
	.video-controls {
		margin: 24px 0 0 50px;
		@include bp(md) {
			margin: 24px 0 0 100px;
		}
		> .flex {
			margin-right: 32px;
			// align-items: center;
			align-items: baseline;
		}
		a {
			margin: 0 0 0 8px;
			font-size: 14px;
			font-weight: 300;
			&:after {
				bottom: 0px;
			}
		}
		.play-audio,
		.play-video {
			cursor: pointer;
		}
		.play-button {
			position: relative;
			top: 1px;
		}
	}
}

.ecosystem {
	.canvas-ecosystem {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		position: absolute;
		@include bp(md) {
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: -1;
		}
	}
	.inner > .flex:first-of-type {
		flex-direction: column-reverse;
		@include bp(md) {
			flex-direction: row;
		}
	}
	.flex-left {
		max-width: 867px;
		margin-top: 100px;
		@include bp(md) {
			margin-top: 0;
		}
	}
	.flex-right {
		width: 100%;
		align-items: center;
		align-self: center;
		h5, p {
			font-weight: 700;
		}
	}
	p:last-of-type {
		margin-top: 48px;
	}
	.grid {
		margin-top: 48px;
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		@include bp(md) {
			margin-top: 187px;
		}
	}
	.grid-item {
		width: 416px;
		max-width: 416px;
		margin-bottom: 40px;
		@include bp(md) {
			width: 300px;
			margin-bottom: 106px;
		}
		@include bp(lg) {
			width: 350px;
		}
		@include bp(xl) {
			width: 416px;
		}
		p {
			width: 304px;
			margin-top: 12px;
			// font-family: $book;
		}
	}
}

.landscape {
	margin: 0;
	overflow: hidden;
	.inner {
		width: 100%;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		align-items: center;
		justify-content: center;
		pointer-events: none;
		@include bp(md) {
			width: auto;
			left: 50%;
			transform: translateX(-50%);
		}
	}
	h5 {
		max-width: 872px;
		margin-bottom: 32px;
		text-align: center;
		pointer-events: none;
	}
	a {
		background-color: transparent;
		pointer-events: auto;
		transition: border 0.6s, border-color 0.6s, background-color 0.6s, color 0.6s, opacity 0.6s;
		&:hover {
			border-color: $white;
			background-color: $white;
			color: $red;
		}
		&:before,
		&:after {
			background-color: transparent;
			border-color: transparent;
		}
	}
	.terrain {
		max-height: 100vh !important;
		margin: 1px 0;
		padding: 0;
		outline: none;
		display: block;
		position: relative;
		@include bp(md) {
			max-height: auto;
		}
	}
	.spotlight {
		position: absolute;
		top: 50%;
		left: 50%;
		width: 50%;
		height: 0;
		padding-bottom: 50%;
		box-shadow: 0 0 0 10000px;
		border-radius: 50%;
		transform: translate(-50%, -50%);
		@include bp(lg) {
			width: 33%;
			padding-bottom: 33%;
		}
		@include bp(xl) {
			width: 25%;
			padding-bottom: 25%;
		}
	}
}

.nodes {
	margin: 106px 0 0;
	.inner {
		display: block;
		> .flex {
			&:first-of-type {
				margin-bottom: 24px;
			}
			&:nth-of-type(2) {
				justify-content: space-between;
				@include bp(md) {
					flex-direction: row;
					.flex-left,
					.flex-right {
						width: 46%;
					}
				}
				@include bp(lg) {
					.flex-left,
					.flex-right {
						width: auto;
					}
				}
			}
		}
	}
	.list {
		@include bp(xl) {
			margin-right: 112px; // so dumb
		}
		.flex {
			align-items: center;
			&:not(:first-of-type) {
				margin-top: 16px;
			}
		}
		p {
			font-weight: 700;
		}
	}
	h4 {
		max-width: 528px;
		margin-bottom: 88px;
	}
	a.a-inline  {
		margin: 0 0 0 4px !important;
		color: $white;
		&.sm {
			font-size: 14px;
			line-height: 132%;
		}
	}
	a:last-of-type {
		@include bp(md) {
			margin-left: 40px;
		}
	}
	img {
		width: 80px;
		height: 80px;
		margin-right: 32px;
	}
	.mobile-only {
		margin-top: 80px;
		justify-content: space-between;
		@include bp(md) {
			display: none;
		}
	}
}

.cards {
	margin-top: 0;
	.canvas-cards {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		// z-index: 0;
		z-index: 2;
		pointer-events: none;
	}
	.inner {
		padding: 320px 0;
		@include bp(lg) {
			> .flex {
				flex-direction: row;
			}
		}
	}
	.flex {
		width: 100%;
		z-index: 1;
		justify-content: space-between;
	}
	.flex-right {
		margin-top: 174px;
	}
	h6 {
		margin-bottom: 32px;
		@include bp(lg) {
			margin-bottom: 64px;
		}
		@include bp(xl) {
			margin-bottom: 96px;
		}
	}
	button {
		margin-top: 32px;
		align-self: flex-start;
		@include bp(md) {
			margin-top: auto;
		}
	}

	.big-card {
		width: 100%;
		max-width: 640px;
		height: 100%;
		max-height: 650px;
		padding: 80px 32px;
		position: relative;
		overflow: hidden;
		@include bp(md) {
			width: 100%;
			height: 450px;
		}
		@include bp(lg) {
			width: 96%;
			height: 550px;
		}
		@include bp(xl) {
			width: 640px;
			height: 650px;
			padding: 120px 80px 88px;
		}
	}
	.border-left,
	.border-right {
		width: 100%;
		height: 88%;
		position: absolute;
		top: 0;
		left: 0;
		border-left: 1px solid $gray3;
		z-index: -1;
	}
	.border-right {
		top: 12%;
		border-left: none;
		border-right: 1px solid $gray3;
	}
	.big-card:after,
	.big-card:before {
		position: absolute;
		content: '';
		// width: calc(100% - 1px);
		width: 100%;
		z-index: -1;
	}
	.big-card:before {
		height: 12%;
		top: 0px;
		right: -1px;
		border: 1px solid $gray3;
		border-width: 1px 2px 0px 0px;
		transform: skew(45deg);
		transform-origin: right bottom;
	}
	.big-card:after {
		height: 12%;
		bottom: 0px;
		left: -1px;
		border: 1px solid $gray3;
		border-width: 0px 0px 1px 2px;
		transform: skew(45deg);
    	transform-origin: left top;
	}
}

.treasury {
	margin-top: 0;
	.canvas-treasury {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		position: absolute;
		@include bp(md) {
			top: 0;
			left: 50%;
			transform: translateX(-50%);
			z-index: -1;
		}
	}
	.inner {
		@include bp(md) {
			flex-direction: row;
		}
	}
	.flex-left {
		max-width: 528px;
		.btn-inline {
			display: inline;
			color: $white;
		}
	}
	.flex-right {
		width: 100%;
		height: auto;
		margin: 160px 0;
		position: relative;
		align-self: stretch;
		justify-content: center;
		text-align: center;
		@include bp(md) {
			margin: 0;
		}
	}
	h2 {
		margin-bottom: 24px;
	}
	h3 {
		margin-top: 24px;
	}
	.positions {
		height: auto;
		flex-wrap: wrap;
		p {
			margin: 48px 32px 0 0;
			padding-bottom: 8px;
			font-weight: 700;
			transition: transform 0.3s;
			cursor: pointer;
			&:last-of-type {
				margin-right: 0;
			}
			&:hover {
				transform: translateY(-8px);
			}
		}
	}
	.btn-primary {
		margin-top: 56px;
		display: inline-block;
	}

	.canvas-overlay {
		width: auto;
		height: 100%;
		// margin-top: 160px;
		position: relative;
		justify-content: center;
		align-items: flex-start;
		z-index: 0;
		@include bp(md) {
			margin-top: 0;
		}
	}
	.popups {
		position: absolute;
		background: transparent;
	}
	.popup {
		max-width: 328px;
		padding: 40px 32px;
		position: absolute;
		background-color: $white;
		p {
			color: $black;
		}
	}

}

.roadmap {
	overflow-x: hidden;
	.inner {
		max-width: auto;
		@include bp(lg) {
			max-width: 800px;
		}
		@include bp(xl) {
			max-width: 1088px;
		}
		> p {
			max-width: 416px;
		}
	}
	h1 {
		margin-bottom: 114px;
		white-space: nowrap;
		span {
			color: $black;
			-webkit-text-stroke: 1px $white;
			// -webkit-text-fill-color: $lightgray;
		}
	}
	.roadmap-cursor {
		width: 78px;
		height: 78px;
		border: 2px solid $red;
		border-radius: 50%;
		position: absolute;
		transform: translate(-50%, -50%) scale(0);
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		background-color: $red;
		z-index: 10;
		&:focus {
			outline: none;
		}
		span {
			flex: 1;
			pointer-events: none;
			color: $white;
			font-size: 12px;
			font-weight: 700;
		}
	}
	.roadmap-slider {
		width: 100%;
		height: 440px;
		margin: 56px 0 40px;
		position: relative;
		@include bp(md) {
			width: 100vw;
			// width: 100vw;
			// margin-right: -50vw;
			// margin-left: calc(-50vw + 185px); //figure this math out
			// position: relative;
			// left: 50%;
			// right: 50%;
		}
	}
	.glide__track {
		cursor: none;
	}
	.glide__slide {
		// max-width: 416px;
		height: 58px;
		display: flex;
		flex-direction: column;
		background-color: $gray5;
		overflow: hidden;
		transition: height 0.3s, background-color 0.3s, color 0.3s;
		p {
			color: $white;
		}
		ul {
			list-style-type: none;
		}
		li {
			font-family: $nexa;
			font-size: 16px;
			font-weight: 300;
			line-height: 164%;
			&:before {
				content: "■";
				display: inline-block;
				width: 1em;
				color: $gray2;
			}
			&.red {
				&:before {
					color: $red;
				}
			}
		}
		.x {
			font-weight: 700;
			transform: rotate(0deg);
			transition: transform 0.6s;
		}
	}
	.glide__slide--active {
		height: 440px;
		background-color: $white;
		p {
			color: $black;
		}
		.x {
			transform: rotate(-45deg);
		}
	}
	.top-bar {
		height: 58px;
		padding: 16px 24px;
		display: flex;
		justify-content: space-between;
	}
	.collapsed-content {
		height: auto;
		margin-top: auto;
		padding: 0 24px;
	}
	.tbd {
		color: $gray2;
	}
	.legend {
		height: 20px;
		margin: 40px 0 16px;
		padding: 0 24px;
		p {
			margin-right: 16px;
			color: $gray2;
		}
		span {
			font-size: 16px;
		}
		.red {
			color: $red;
		}
	}
	.glide__bullets {
		position: absolute;
		top: -5%;
		right: 0;
		bottom: auto;
		left: auto;
		transform: translateX(0);
		cursor: pointer !important;
		@include bp(md) {
			position: absolute;
			top: -48px;
			right: 25%;
		}
		@include bp(xl) {
			right: 22%;
		}
	}
	.glide__bullet {
		border: none;
		background-color: $gray4;
		cursor: pointer !important;
	}
	.glide__bullet--active {
		border: none;
		background-color: $red;
	}
}

.news {
	margin-top: 300px;
	.inner {
		max-width: auto;
		@include bp(lg) {
			max-width: 800px;
		}
		@include bp(xl) {
			max-width: 1088px;
		}
		> h4 {
			max-width: 300px;
			@include bp(xl) {
				max-width: 468px;
			}
		}
		> .flex {
			justify-content: space-between;
			@include bp(lg) {
				flex-direction: row;
			}
		}
	}
	p {
		font-weight: 700;
		&.md, &.ti {
			font-weight: 300;
		}
		&.md {
			max-width: 416px;
			margin-top: 32px;
			@include bp(lg) {
				margin-top: 0;
			}
		}
		&.ti {
			margin-bottom: 8px;
		}
	}
	.social {
		margin-top: 80px;
		align-items: center;
		@include bp(lg) {
			margin-top: 261px;
		}
	}
	.twitter {
		margin-left: 16px;
		margin-right: 8px;
	}
	.article {
		margin-top: 40px;
		a {
			display: block;
			font-size: 18px;
			color: $white;
		}
	}
	.parallax {
		position: absolute;
	}
	.parallax-dots {
		width: 136px;
		height: 146px;
		top: -40%;
		left: -7.5%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		@include bp(md) {
			top: -25%;
			left: -17.5%;
		}
		div {
			width: 34px;
			height: 29.2px;
			height: 34px;
			display: flex;
			align-items: center;
			justify-content: center;
			&:nth-child(-n+16) {
				span {
					width: 5px;
					height: 5px;
				}
			}
			&:nth-child(-n+12) {
				span {
					width: 4px;
					height: 4px;
				}
			}
			&:nth-child(-n+8) {
				span {
					width: 3px;
					height: 3px;
				}
			}
			&:nth-child(-n+4) {
				span {
					width: 2px;
					height: 2px;
				}
			}
		}
		span {
			width: 6px;
			height: 6px;
			// width: 2px;
			// height: 2px;
			border-radius: 50%;
			display: block;
			background-color: $white;
		}
	}
	.parallax-lines {
		width: 24px;
		height: 227px;
		top: 75%;
		right: -1%;
		display: flex;
		flex-direction: column;
		span {
			width: 24px;
			height: 1px;
			margin-bottom: 17px;
			background-color: $gray4;
			@include bp(md) {
				transform: rotate(45deg);
			}
		}
	}
}

.story {
	padding-bottom: 250px;
	.canvas-story {
		width: 100%;
		height: 100%;
		margin: 0 auto;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 2;
		pointer-events: none;
	}
	.inner {
		> .flex {
			justify-content: space-between;
			&:last-of-type {
				margin-top: 55px;
				@include bp(md) {
					flex-direction: row;
				}
			}
		}
	}
	p.sm {
		margin-bottom: 24px;
		font-weight: 700;
	}
	.flex-left {
		width: 100%;
		max-width: 640px;
	}
	.flex-right {
		width: 100%;
		max-width: 528px;
		margin-top: 8px;
	}
	button {
		margin-top: 48px;
	}
	.parallax {
		position: absolute;
		top: 7%;
		right: 15%;
	}
}

.team {
	margin-top: 0;
	.inner > .flex {
		@include bp(lg) {
			flex-direction: row;
		}
	}
	p {
		&.md {
			margin: 32px 0 0 auto;
			@include bp(lg) {
				max-width: 528px;
				// margin: 32px 0 0 auto;
			}
		}
		&.sm {
			&.mixitup-control-active {
				color: $white;
				&:hover {
					color: $white;
				}
			}
		}

	}
	.team-tabs {
		margin: 96px 0 16px;
		flex-wrap: wrap;
		p {
			margin-right: 32px;
			margin-bottom: 16px;
			font-weight: 700;
			color: $gray3;
			cursor: pointer;
			transition: color 0.3s;
			&:hover {
				color: $gray2;
			}
		}
	}
	.team-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.team-member {
		width: 100%;
		margin-bottom: 32px;
		position: relative;
		@include bp(md) {
			width: calc(50% - 15px);
		}
		@include bp(xl) {
			width: 640px;
		}
		.reveal-content {
			width: 100%;
			height: auto;
			position: absolute;
			top: 0;
			left: 0;
			background-color: $white;
			z-index: 1;
			opacity: 0;
			visibility: hidden;
			transition: opacity 0.3s, visibility 0.3s;
			&.active {
				opacity: 1;
				visibility: visible;
				.expander-close {
					transform: rotate(45deg);
				}
			}
			.expander-close {
				position: absolute;
				top: 50px;
				right: 50px;
				font-family: $nexa;
				font-size: 24px;
				font-weight: 700;
				cursor: pointer;
				transition: transform 0.3s 0.15s;
			}
			h6, p, .expander {
				color: $black;
			}
			h6, p.ti {
				text-transform: uppercase;
			}
			h6 {
				margin-bottom: 8px;
				text-transform: uppercase;
			}
			p.md {
				margin: 80px 0 0;
			}
			.border {
				width: 100%;
				border-top: 1px solid $white;
				position: absolute;
				top: 0;
				left: 0;
			}
			.fold {
				width: 0px;
				height: 0px;
				position: absolute;
				border-style: solid;
				background: transparent;
				display: block;
				border-color: transparent $white transparent $white;
				top: 0px;
				left: 0px;
				border-width: 0px 0px 50px 50px;
				&:after {
					content: '';
					width: 0px;
					height: 0px;
					position: absolute;
					border-style: solid;
					background: transparent;
					display: block;
					border-color: transparent $black transparent $black;
					top: -1px;
					left: -52px;
					border-width: 0px 0px 50px 50px;
				}
			}
		}
		.reveal-wrapper {
			padding: 40px 50px;
		}
	}
	.employee {
		width: 100%;
		height: auto;
		padding: 40px 50px;
		border: 1px solid $gray3;
		border-top: 0;
		position: relative;
		justify-content: center;
		transition: background-color 0.3s, color 0.3s;
		.expander-open {
			position: absolute;
			top: 50px;
			right: 50px;
			font-family: $nexa;
			font-size: 24px;
			font-weight: 700;
			color: $white;
			cursor: pointer;
		}
		h6, p.ti {
			text-transform: uppercase;
		}
		h6 {
			margin-bottom: 8px;
		}
		p.md {
			margin: 80px 0 0;
			// margin: 0;
		}
		.border {
			width: 100%;
			border-top: 1px solid $gray3;
			position: absolute;
			top: 0;
			left: 0;
		}
		.fold {
			width: 0px;
			height: 0px;
			position: absolute;
			border-style: solid;
			background: transparent;
			display: block;
			border-color: transparent $gray3 transparent $gray3;
			top: 0px;
			left: 0px;
			border-width: 0px 0px 50px 50px;
			&:after {
				content: '';
				width: 0px;
				height: 0px;
				position: absolute;
				border-style: solid;
				background: transparent;
				display: block;
				border-color: transparent $black transparent $black;
				top: -1px;
				left: -52px;
				border-width: 0px 0px 50px 50px;
			}
		}
	}
	.team-pages {
		margin: 64px 0 0;
		display: flex;
		justify-content: space-between;
		@include bp(md) {
			margin: 64px auto 0;
			justify-content: flex-start;
		}
		button {
			border: none;
			border-color: $black;
			transform: translateZ(0px);
			color: $gray3;
			transition: color 0.3s;
			&:hover {
				color: $gray2;
			}
			&.mixitup-control-active {
				color: $white;
			}
		}
		.mixitup-control:not(.mixitup-control-prev, .mixitup-control-next) {
			display: none;
			@include bp(md) {
				display: flex;
			}
		}
		.mixitup-control-truncation-marker {
			color: $gray3;
			align-self: center;
		}
	}
}

footer {
	margin-top: 260px;
	padding-bottom: 52px;
	.inner > .flex {
		width: 100%;
		@include bp(md) {
			flex-direction: row;
		}
		&:last-of-type {
			justify-content: space-between;
			margin-top: 107px;
		}
		.disclaimer-link {
			display: flex;
			a {
				font-size: inherit;
				color: inherit;
			}
		}
	}
	// h3 {
	// 	&:first-of-type {
	// 		@include bp(md) {
	// 			margin-right: 131px;
	// 		}
	// 	}
	// }
	.footer-links {
		align-items: flex-start;
		justify-content: space-between;
		@include bp(md) {
			align-items: flex-end;
		}
		p {
			margin-bottom: 8px;
			@include bp(md) {
				margin-bottom: 16px;
				text-align: center;
			}
		}
	}
	.spread-effect {
		width: 100%;
		position: relative;
		@include bp(md) {
			width: 33.33%;
		}
		&:first-of-type {
			margin-bottom: 32px;
			@include bp(md) {
				margin-bottom: 0;
			}
		}
		&:nth-of-type(2) {
			@include bp(md) {
				text-align: center;
			}
		}
		&:last-of-type {
			//text-align: right;
		}
		.stroke {
			position: absolute;
			color: $black;
			-webkit-text-stroke: 1px $gray2;
		}
		.se-foreground {
			position: relative;
			z-index: 1;
		}
		a {
			font-family: $nexa;
			font-size: 32px;
			font-weight: 700;
			line-height: 132%;
			color: $white;
			justify-content: flex-start;
			@include bp(xl) {
				font-size: 56px;
				line-height: 108%;
			}
			span {
				opacity: 1 !important;
			}
		}
	}
	.social {
		flex-direction: column-reverse;
	}
	.social-icons {
		margin-bottom: 32px;
		@include bp(md) {
			margin-bottom: 0;
		}
		a:not(:last-of-type) {
			margin-right: 8px;
		}
	}
}



/* ========== */
/*  Addendum  */
/* ========== */

.mobile-only {
	display: flex;
	@include bp(lg) {
		display: none;
	}
}

.desktop-only {
	display: none;
	@include bp(lg) {
		display: flex;
	}
}


.tippy-box[data-theme~='thorfi'] {
	margin: 0;
	padding: 40px 32px;
	border-radius: 0;
	background-color: $white;
	font-family: $nexa;
	font-size: 12px;
	line-height: 164%;
	color: $black;
}

.app, .mobile-menu {
	a:not(.imglink) {
		margin-right: 32px;
		position: relative;
		color: $white;
		&:after {
			content: "";
			width: 0%;
			height: 1px;
			margin-top: 5px;
			position: absolute;
			bottom: -5px;
			left: 0;
			background-color: $red;
			transition: width 0.3s ease 0.2s;
		}
		&.active,
		&:hover {
			&:after {
				width: 100%;
			}
		}
	}
	a.imglink {
		// width: 100%;
		// height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			margin: -1px 0 0 !important;
		}
	}
}

.launch-icon {
	width: 15px;
	height: 15px;
	margin-top: -2px;
	display: flex;
	flex-wrap: wrap;
	&:hover {
		span:nth-child(1) {
			transform: translate(-2px, -2px);
		}
		span:nth-child(2) {
			transform: translate(2px, -2px);
		}
		span:nth-child(3) {
			transform: translate(-2px, 2px);
		}
		span:nth-child(4) {
			transform: translate(2px, 2px);
		}
	}
	span {
		width: 6px;
		height: 6px;
		display: block;
		background-color: $red;
		opacity: 1;
		transition: transform 0.5s;
		&:first-of-type {
			margin: 0 3px 3px 0;
		}
		&:last-of-type {
			margin: 0 0 0 3px;
		}
	}
}

.app {
	display: none;
	@include bp(lg) {
		display: flex;
	}
}

.mobile-menu {
	display: flex;
	@include bp(lg) {
		display: none;
	}
	a:not(.imglink) {
		margin-right: 16px;
	}
	a.imglink {
		// width: 100%;
		// height: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			margin: -2px 0 0 !important;
		}
	}
}

.social-icon {
	transform: rotate(-90deg);
	&:hover {
		.fg {
			stroke-dashoffset: 0;
		}
		// path {
		// 	fill: $red;
		// }
	}
	path {
		transition: fill 0.3s ease-in-out 0.6s;
	}
	.bg {
		fill: none;
		stroke: $white;
	}
	.fg {
		fill: none;
		stroke: $red;
		stroke-dasharray: 100 100;
		stroke-dashoffset: 100;
		stroke-linecap: round;
		stroke-width: 1;
		transition: stroke-dashoffset 0.6s ease-in-out;
	}
}

// .disclaimer-modal {
// 	display: none;
//     position: fixed;
//     z-index: 999;
//     left: 50%;
//     top: 0;
// 	transform: translateX(-50%);
//     width: 100%;
//     height: 100%;
//     overflow: auto;
// 	background: rgba(0,0,0,0.7);
// 	h2 {
// 		font-size: 20px;
// 		margin-bottom: 10px;
// 	}
// 	p {
// 		font-size: 12px;
// 		line-height: 164%;
// 		margin-bottom: 20px;
// 	}
// 	ul {
// 		color: white;
// 		font-size: 12px;
// 		line-height: 164%;
// 		margin-bottom: 20px;
// 		font-family: $nexa;
// 		padding-left: 15px;
// 		li {
// 			list-style-type: disc;
// 			margin-bottom: 10px;
// 		}
// 	}
// 	.modal-content {
// 		margin: 120px auto 0;
// 		border: 1px solid $gray3;
// 		position: relative;
// 		width: 80%;
// 		height: 80%;
// 		padding-top: 50px;
// 		background-color: rgba(0,0,0,1);
// 		padding: 40px;
// 		overflow: auto;

// 	}
// 	.modal-close {
// 		width: 20px;
// 		height: 20px;
// 		font-size: 20px;
// 		color: white;
// 		position: absolute;
// 		top: 20px;
// 		right: 20px;
// 		cursor: pointer;
// 	}
// }

#terms {
	width: 80%;
	max-width: 800px;
	margin: auto;
	padding-top: 150px;
	padding-bottom: 100px;
	ul {
		color: white;
		font-size: 12px;
		line-height: 164%;
		margin-bottom: 20px;
		font-family: $nexa;
		padding-left: 15px;
		li {
			list-style-type: disc;
			margin-bottom: 10px;
		}
	}
	h1 {
		font-size: 40px;
    	margin-bottom: 0.5em;
	}
	h2 {
		font-size: 20px;
		margin-bottom: 10px;
	}
	p {
		font-size: 12px;
		line-height: 164%;
		margin-bottom: 20px;
	}
}
